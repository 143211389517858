import { Inject, Injectable, Optional } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { REDIRECT } from '../modules/legacy/services/legacy-redirect.provider';
import { SESSION_SERVICE } from '../modules/legacy/services/legacy-session-service.provider';
import { FeatureRightCode } from '../modules/session/model/feature-right.model';
import { MnbFeatureToggle, MnbSession } from './session.model';
import { initSentryUserContext } from '@shared-lib/tooling/sentry/sentry.util';


@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private sessionHandler: SessionHandler;
    private sessionPromise: Promise<MnbSession>;
    private sessionResolver: (MnbSession) => void;
    private session: MnbSession;

    constructor(
        @Optional() @Inject(SESSION_SERVICE) private sessionService: any,
        @Optional() @Inject(REDIRECT) private redirect: any
    ) {
        this.sessionPromise = new Promise<MnbSession>((resolve) => {
            this.sessionResolver = resolve;
        });

        if (this.sessionService) {
            this.sessionHandler = {
                logIn: () => {
                    return null;
                },
                logOut: (session) => {
                    this.sessionService.logout();
                }
            };
            this.sessionService.session.$promise.then((session: MnbSession) => {

                if (session.login) {
                    initSentryUserContext({
                        id: session.login.id,
                        tenantId: session.customer.id,
                    });
                }
                this.sessionResolver(session);
            });
            this.session = this.sessionService.session;
        }

    }

    public logIn(sessionHandler: SessionHandler): Promise<MnbSession> {
        this.sessionHandler = sessionHandler;
        sessionHandler.logIn().then(session => {
            this.session = session;
            this.sessionResolver(session);
        });
        return this.sessionPromise;
    }

    public getSession(): MnbSession {
        return this.session || {};
    }

    public getSessionPromise(): Promise<MnbSession> {
        return this.sessionPromise.then(session => {
            return session || {};
        });
    }

    public async isLoggedInAsync(): Promise<boolean> {
        return this.sessionPromise.then(session => {
            return session != null && session.login != null && session.login.id != null;
        });
    }

    public isLoggedIn(): boolean {
        return !isNullOrUndefined(this.getSession().login);
    }

    public wipFeatureEnabled(): boolean {
        return this.hasFeature(MnbFeatureToggle.WIP_FEATURES);
    }

    public isMatchedLoginId(id: number) {
        return this.isLoggedIn() && this.getSession().login.id === id;
    }

    public isFirstProcessingNeeded(): boolean {
        if (this.sessionService) {
            return this.sessionService.isFirstProcessingNeeded(this.getSession());
        }
        return false;
    }

    public logout() {
        this.sessionHandler.logOut(this.session);
    }

    public isNowTenant() {
        const session = this.getSession();
        return session && session.tenant && session.tenant.tenantTypeCode === 'NOW';
    }

    public isMultiTenant() {
        const session = this.getSession();
        return session && session.tenant && session.tenant.tenantTypeCode === 'MLT';
    }

    public hasRight(rightCode: FeatureRightCode) {
        const session = this.getSession();
        return !session.featureRights.restrictedFeatures.includes(rightCode);
    }

    public hasFeature(toggle: MnbFeatureToggle) {
        const session = this.getSession();
        if (session.tenant && session.tenant.settings && session.tenant.settings.featureToggles) {
            return session.tenant.settings.featureToggles.includes(toggle);
        }
        return false;
    }

    public saveBackTo(page: string) {
        this.sessionService.backState = page;
    }

    public goToLoginPage(timeout?: boolean) {
        if (this.redirect) {
            this.redirect.redirectToLogin(timeout);
        }
    }

    public getBackState(): string {
        if (this.sessionService) {
            return this.sessionService.backState;
        }
        return null;
    }

    public removeBackState() {
        if (this.sessionService) {
            delete this.sessionService.backState;
        }
    }
}

export interface SessionHandler {

    logIn(): Promise<MnbSession>;

    logOut(session: MnbSession): void;
}
